/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { StatusConstant } from '../../constants/status.constant'; //// CONTROLLER ////

(function () {
  'use strict';

  angular.module('comcast.lite').controller('ViewDetailController', ['$interval', '$scope', '$q', '$rootScope', '$state', '$stateParams', '$window', '$mdDialog', 'LiteDeliveryResourceFactory', 'SpotResourceFactory', '$timeout', 'NotificationService', 'AssetResourceFactory', 'AuthenticationResourceFactory', 'loginService', 'EndPointService', 'socket', 'StatusService', function ($interval, $scope, $q, $rootScope, $state, $stateParams, $window, $mdDialog, LiteDeliveryResourceFactory, SpotResourceFactory, $timeout, NotificationService, AssetResourceFactory, AuthenticationResourceFactory, loginService, EndPointService, socket, StatusService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    var pollForFlip;
    var statusConstants = new StatusConstant();
    /* BINDABLE : DATA */

    vm.delivery = {};
    vm.spotStatuses = statusConstants.spots;
    vm.deliveriesStatuses = statusConstants.deliveries;
    vm.deliveriesStatuses.COMPLETED.status = 'Delivered';
    vm.spot = null;
    vm.assetMap = null;
    vm.proxyAsset = null;
    vm.needsConversion = false;
    vm.displayError = false;
    vm.polling = false;
    vm.asset = null;
    vm.isLite = loginService.getSessionData().liteAccount;
    vm.isAdmin = loginService.isAdmin();
    vm.downloadLink = EndPointService.assetDownloadEndpoint;
    vm.loading = {
      spot: false,
      dist: false,
      mezz: false,
      qt: false,
      mp3: false,
      vqc: false
    };
    vm.storyBoard = [];
    vm.showThumbnailStrip = false;
    /* BINDABLE : METHODS*/

    vm.downloadAsset = downloadAsset;
    vm.viewMetadataReport = viewMetadataReport;
    vm.viewHistoryReport = viewHistoryReport;
    vm.convertProxy = convertProxy;
    vm.openThumbnailDialog = openThumbnailDialog;
    vm.openMenu = openMenu;
    vm.copySpotProxy = copySpotProxy;
    vm.statusService = StatusService;
    vm.openSpotRejected = openSpotRejected;
    vm.expandThumbnails = expandThumbnails;
    /* EVENTS */
    //Register any event listeners

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (toState.name !== 'viewDetail') {
        $interval.cancel(pollForFlip);
        pollForFlip = null;
      }
    });
    /* RECURRING CODE */

    pollForFlip = $interval(function () {
      _loadData(false, true);
    }, 120000);
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function downloadAsset(uuid, type, alias) {
      if (uuid === undefined) {
        return;
      }

      alias = alias.replace('__', '_');
      NotificationService.showNotificationToast('Download(s) has started.'); // Build download links

      var endpoint = EndPointService.assetDownloadEndpoint + '/' + vm.spot.id + '/';
      $window.open(endpoint + '?' + 'assetUuid=' + uuid + '&' + 'baseFilename=' + alias + '&' + 'authorization=' + loginService.getJwt());
    }

    function viewMetadataReport() {
      return $mdDialog.show({
        controller: 'MetadataReportController',
        controllerAs: 'vm',
        template: require('./metadataReportDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: true,
        // For small screens only
        resolve: {
          reportData: function reportData() {
            return {
              assetContentId: vm.spot.AssetMap.QC_REPORT_DATA[0],
              spotId: vm.spot.id
            };
          }
        }
      });
    }

    function viewHistoryReport() {
      // Make request to get __sessionKey
      AuthenticationResourceFactory.getSessionKey({}, function (sessionKey) {
        // Append __sessionKey to request to Node (instead of RE)
        $window.open(EndPointService.assetDownloadReportEndpoint + '/' + vm.spot.id + '/' + vm.spot.isci + '?sessionKey=' + sessionKey.data + '&' + 'authorization=' + loginService.getJwt(), '_blank'); // NODE: Take __sessionKey and pipe request to RE
        // NODE: Open request directly into response to stream data back to browser
      }, function (err) {// Unable to retrieve the session key, maybe the user is logged out.
      });
      return true;
    }

    function convertProxy() {
      if (vm.spot) {
        vm.polling = true;
        SpotResourceFactory.generateProxy({
          id: vm.spot.id
        }, {}).subscribe(function (asset) {
          NotificationService.showNotificationToast(asset); // Start listening for the current spot to be transcoded

          _listenForProxy();
        }, function (err) {
          NotificationService.showNotificationToast(err);
        });
      }
    }

    function openThumbnailDialog(thumbnailIndex) {
      $mdDialog.show({
        controller: 'ThumbnailDialogController',
        controllerAs: 'vm',
        template: require('./thumbnailDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          storyBoard: vm.assetMap.SPOT_STORYBOARD,
          thumbnailIndex: thumbnailIndex
        }
      });
    }

    function openMenu($mdMenu) {
      $mdMenu.open();
    }

    function copySpotProxy() {
      return $mdDialog.show({
        controller: 'CopySpotProxyDialogController',
        controllerAs: 'vm',
        template: require('../spots/copySpotProxyDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
        // For small screens only
        locals: {
          apiURL: vm.spot.apiURL
        }
      }).then(function () {//User closed popup
      }, function (err) {//user cancelled the popup
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _listenForProxy() {
      socket.on('HTML5 Transcode For Spot ' + vm.spot.id, function (data) {
        // This isn't flip, so don't bother polling
        $interval.cancel(pollForFlip);

        if (data.html5TranscodeStatus === 'COMPLETED' && !bowser.msie) {
          vm.assetMap.HTML5_PROXY.push(data.proxyAsset); // Retrieve the new Proxy asset to load into view

          if (vm.assetMap && vm.assetMap.HTML5_PROXY && vm.assetMap.HTML5_PROXY[0] && !bowser.msie && !bowser.msedge && !bowser.safari) {
            AssetResourceFactory.getVideoAsset({
              uuid: vm.assetMap.HTML5_PROXY[0]
            }, {}, function success(asset) {
              vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+

              vm.proxyType = 'video/webm';
              vm.polling = false;
            }, function failure(err) {
              console.log('FAILURE');
              console.log(err);
              vm.polling = false;
            });
          } else if (vm.assetMap && vm.assetMap.FLV_PROXY && vm.assetMap.FLV_PROXY[0] && (bowser.msie || bowser.msedge || bowser.safari)) {
            AssetResourceFactory.getVideoAsset({
              uuid: vm.assetMap.FLV_PROXY[0]
            }, {}, function success(asset) {
              vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+

              vm.proxyType = 'video/mp4';
              vm.polling = false;
            }, function failure(err) {
              console.log('FAILURE');
              console.log(err);
            });
          }
        }
      });
    }

    function _loadData(loadThumbs, loadQuiet) {
      loadThumbs = loadThumbs === undefined ? false : loadThumbs;
      var getFunction = loadQuiet ? LiteDeliveryResourceFactory.getDeliveryQuiet : LiteDeliveryResourceFactory.get;
      getFunction({
        id: $stateParams.id
      }, function (spot) {
        if (spot.xhrStatus && spot.xhrStatus === 'complete') {
          vm.spot = spot.data;
        } else {
          vm.spot = spot;
        }

        vm.spot.statusBar = vm.statusService.readViewStatus(vm.spot);
        vm.assetMap = vm.spot.AssetMap; // Don't just poll willy-nilly

        var checkStatus = vm.spot.ParentOrderItem ? vm.spot.ParentOrderItem.deliveryStatus : vm.spot.status;

        if (['CANCELLED', 'REJECTED', 'SENT', 'PENDING_MEDIA', 'COMPLETED', 'FAILED', 'PENDING_TRAFFIC', 'PENDING_PROD_SVCS', 'PENDING_META', 'PARKED', 'SHIPPING', 'CANCELLED', 'REMOVED_FROM_ORDER', 'DOWNLOAD_AVAILABLE', 'EXPIRED'].indexOf(checkStatus) > -1) {
          $interval.cancel(pollForFlip);
        }

        if (vm.spot.html5TranscodeStatus == 'TRANSCODING') {
          vm.polling = true;

          _listenForProxy();
        }

        if (vm.spot.duration > vm.spot.expectedDuration) {
          vm.deliveriesStatuses.COMPLETED.status = 'Long Duration Delivered';
        }

        if (vm.spot.html5TranscodeStatus == 'FAILED') {
          vm.displayError = true;
          vm.assetMap.HTML5_PROXY = [];
        } else if (vm.assetMap && vm.assetMap.HTML5_PROXY && vm.assetMap.HTML5_PROXY[0] && !bowser.msie && !bowser.msedge && !bowser.safari) {
          AssetResourceFactory.getVideoAsset({
            uuid: vm.assetMap.HTML5_PROXY[0]
          }, {}, function success(asset) {
            vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+

            vm.proxyType = 'video/webm';
          }, function failure(err) {
            console.log('FAILURE');
            console.log(err);
          });
        } else if (vm.assetMap && vm.assetMap.FLV_PROXY && vm.assetMap.FLV_PROXY[0] && (bowser.msie || bowser.msedge || bowser.safari)) {
          AssetResourceFactory.getVideoAsset({
            uuid: vm.assetMap.FLV_PROXY[0]
          }, {}, function success(asset) {
            vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+

            vm.proxyType = 'video/mp4';
          }, function failure(err) {
            console.log('FAILURE');
            console.log(err);
          });
        } // if it has a MEZZANINE but not an html5Asset, it needs to be converted
        // if it has NEITHER proxy, then it probably hasn't been ingested yet/is rejected/etc.


        vm.needsConversion = vm.assetMap && vm.assetMap.MEZZANINE && vm.assetMap.MEZZANINE.length > 0 && !bowser.msie && !bowser.msedge && !bowser.safari ? !(vm.assetMap.HTML5_PROXY && vm.assetMap.HTML5_PROXY[0]) : false;

        if (loadThumbs) {
          loadThumbnails();
        }
      });
    }

    function expandThumbnails(showPane) {
      vm.showThumbnailStrip = showPane;

      if (showPane) {
        if (vm.storyBoard.length === 0) {
          loadThumbnails();
        }
      }
    }

    function loadThumbnails() {
      var spotStoryboard = angular.copy(vm.assetMap.SPOT_STORYBOARD);

      if (vm.assetMap.SD_HD_SLATE_THUMBNAILS.length) {
        spotStoryboard.unshift(vm.assetMap.SD_HD_SLATE_THUMBNAILS[0]);
      } // We still need all these in the assetMap so we can load the correct thumbnails on click


      vm.assetMap.SPOT_STORYBOARD = angular.copy(spotStoryboard);
      $q.all(spotStoryboard.map(function iterator(cur, index, arr) {
        return AssetResourceFactory.getImageAsset({
          uuid: cur
        }, {}).$promise;
      })).then(function success(storyboardImages) {
        vm.storyBoard = storyboardImages.map(function (img, ind, list) {
          var blob = new Blob([img.data], {
            type: img.config['Content-Type']
          });
          return URL.createObjectURL(blob);
        });
      }, function failure(err) {
        console.log(err);
        vm.storyBoard = [];
      });
    }

    function openSpotRejected() {
      if (vm.spot && vm.spot.id) {
        $mdDialog.show({
          controller: 'SpotRejectionsDialogController',
          controllerAs: 'vm',
          template: require('../common/dialogs/spotRejectionDialog-template.html'),
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          fullscreen: true,
          locals: {
            id: vm.spot.id,
            type: 'liteOrder'
          }
        });
      }
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      $timeout(function () {
        $rootScope.viewDetailForm = $scope.viewDetailForm;
      });

      _loadData(false);
    }
  }]);
})();